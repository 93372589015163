import React from 'react'

import Layout from '../../components/layout'
import Wizard from '../../components/wizard'
import useStateWithSessionStorage from '../../helpers/useStateWithSessionStorage'

import classNames from 'classnames'

const PrototypePage = () => {
  const [ selectedTemplate, setSelectedTemplate ] = useStateWithSessionStorage('selectedTemplate', templates[0].title)

  const nextTo = selectedTemplate === 'Custom' ? '/onboarding-1/custom-node-type' : '/onboarding-1/tag-types'

  const handleClick = (e, title) => {
    e.preventDefault()
    setSelectedTemplate(title)
  }

  return (
    <Layout>
      <Wizard title="Choose data structure" backTo="/onboarding-1" nextTo={nextTo}>
        <p className="mt-2">Qualdesk Insights allows you to store your data in a way that suits you. Pick from a template or customize your data model.</p>
        <div className="card-deck mt-4">
        {
          templates.map((t, i) => (
            <div className={classNames('card', 'cursor-pointer', 'no-focus-outline', { 'bg-qualdesk-light-blue border-primary': t.title === selectedTemplate })} key={i} onClick={(e) => handleClick(e, t.title)} onKeyPress={e => {}} role="button" tabIndex={i}>
              <div className="card-header py-3">
                <h6 className="card-title font-weight-bold">{t.title}</h6>
                <p className="card-text small">{t.description}</p>
              </div>
              {
                t.custom
                ? <div className="card-body small d-flex align-items-center justify-content-center"><p><i>Choose your own</i></p></div>
                : <div className="card-body">
                    <div className="">{t.containers?.map(c => <span className="badge badge-secondary mr-1 mb-1" key={c}>{c}</span>)}</div>
                    <hr/>
                    <div className="">{t.nodes?.map(c => <span className="badge badge-primary mr-1 mb-1" key={c}>{c}</span>)}</div>
                    <hr/>
                    <div className="">{t.atoms?.map(c => <span className="badge badge-info mr-1 mb-1" key={c}>{c}</span>)}</div>
                  </div>
              }
            </div>
          ))
        }
        </div>
      </Wizard>
    </Layout>
  )
}

export default PrototypePage

const templates = [
  {
    title: 'Simple',
    description: 'Ideal for small teams or people just starting out with user research',
    containers: ['Projects'],
    nodes: ['Insights'],
    atoms: ['Quotes', 'Media'],
  },
  {
    title: 'Advanced',
    description: 'Ideal for larger organizations with multiple teams doing research',
    containers: ['Projects', 'Collections'],
    nodes: ['Insights', 'Hypotheses', 'Ideas'],
    atoms: ['Quotes', 'Media', 'Links', 'Actions', 'Recommendations'],
  },
  {
    title: 'Custom',
    description: 'Create your own data structure and customize terminology',
    custom: true,
  },
]